import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider2 from "../components/slider2"

const pageName = "A PROPOS DE NOUS"
const AboutUs = () => (
  <Layout>
    <SEO title={pageName} />
    <Slider2 title={pageName} />
    <div className="flex flex-col justify-center py-6">
      <div className="container mx-auto">
        <div className="flex flex-col">
          <span className="text-center text-sm pt-2 font-semibold text-blue-600">
            A propos de nous
          </span>
          <h1 className="text-center text-2xl">
            Envie d'en savoir plus sur nous ?
          </h1>
          <span className="text-gray-600 text-xs text-center px-4 pb-8">
            Flemme de laver vos vêtements et ceux de votre famille ?<br />
            Ne vous inquiétez pas, nous le faisons pour vous !
          </span>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 px-5">
            <img
              src="https://media.istockphoto.com/photos/young-boy-doing-housework-at-home-picture-id922221748?b=1&k=6&m=922221748&s=170667a&w=0&h=6oDWQZMB4W0TR6l1ZfmHnwtaNoMJY9BD1p1B3xEhO9Q="
              alt=""
              className="w-full shadow rounded-lg"
            />
          </div>
          <div className="w-full md:w-1/2 px-5">
            <p className="text-gray-600 text-sm text-left">
              Nous sommes un service de pressing en ligne. <br />
              <span className="font-bold">Notre objectif :</span> <br />
              1- Vous faire gagner du temps
              <br />
              2- Vous offrir des vêtements propres bien LAVÉS à des prix très
              concurrentiels. <br />
              <span className="font-bold">Nos procédures :</span> <br />
              1- Vous nous communiquez la liste de vos vêtements à laver. <br />
              2- Nous vous revenons avec la facture. <br />
              3- Factures validées, nous passons ramasser vos vêtements à laver{" "}
              <br />
              4- Nous les traitons/lavons avec soins et nous vous les ramenons 4
              jours plus tard. Vous ne vous déplacez pas. <br />
              5- Vous réglez à la livraison si vous êtes satisfait(e) par mobile
              money ou en espèces au livreur. <br />
              Nous travaillons avec des pressings prestigieux afin d'assurer la
              qualité de nos prestations et satisfaire nos clients.
            </p>
            <Link
              to="/contact-us"
              className="text-white bg-green-600 border-green-600 hover:bg-green-800 hover:border-green-800 font-semibold py-3 px-4 rounded text-center shadow-lg text-xs"
            >
              Contactez-nous
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutUs
